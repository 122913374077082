/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import ReactDOM from "react-dom";
import { DownOutlined, FacebookFilled, InstagramFilled, MenuOutlined, UpOutlined, WechatFilled, WhatsAppOutlined } from '@ant-design/icons';
import { Layout, Typography, Progress, Space, Drawer, Menu, } from "antd"
import Color from "color";



import ReactFullpage from "@fullpage/react-fullpage";

import Icon from './Icon'
import '../../Styles/Public/Landing.scss';

import Home from "../Public/Home"
import Nosotros from "../Public/Nosotros"
import Servicios from "../Public/Servicios"
import Contacto from "../Public/Contacto"

const SEL = "custom-section";
const SECTION_SEL = `.${SEL}`;

const { Content } = Layout;
const { Text } = Typography;

const sections = [
  {
    key: 'home',
    name: 'Inicio',
    theme: 'dark',
    content: ({ ...args }) => <Home {...args} />
  },
  {
    key: 'nosotros',
    name: 'Nosotros',
    theme: 'white',
    content: ({ ...args }) => <Nosotros {...args} />
    // content: ({ ...args }) => <div style={{height: 100, width: 100, backgroundColor: "red"}}></div>//<Nosotros {...args} /> 
  },
  {
    key: 'servicios',
    name: 'Servicios',
    theme: 'dark',
    content: ({ ...args }) => <Servicios {...args} />
  },
  {
    key: 'contacto',
    name: 'Contacto',
    theme: 'white',
    content: ({ ...args }) => <Contacto {...args} />
  },
]

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      section: 0,
      theme: "dark",
      visibleDrawer: false
    }
  }

  onLeave = (origin, destination, direction) => {
    console.log("onLeave", { origin, destination, direction });
    // arguments are mapped in order of fullpage.js callback arguments do something
    // with the event
    this.setState({ section: destination.index })
    setTimeout(() => this.setState({ theme: sections[destination.index].theme }), 220)

  }

  getThemeColor = (theme = this.state.theme) => (theme == "dark") ? 'black' : 'white'
  getThemeColorOpac = (theme = this.state.theme) => (theme == "dark") ? 'white' : 'black'

  render() {

    const { theme, section } = this.state
    const { getThemeColor, getThemeColorOpac } = this

    console.log('section', section);

    return (
      <Content className="landing" >
        {/* Logo de Presentación */}
        <div className="landing-logo">
          <Icon className="landing-logo-svg"
            fill={getThemeColorOpac()}
          />
        </div>
        {/* Boton de regresar al inicio */}
        {/* Texto */}
        <Text className="landing-politics" style={{ color: Color(getThemeColorOpac()).alpha(0.6).hexa() }}>@ 2023 ThrowCode, Todos los derechos reservados</Text>
        <div className="contact-section">
          <div className="contact-section-container">
            <Progress
              size="small"
              className="currentview"
              strokeColor={(theme == "dark") ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)'}
              trailColor={(theme == "dark") ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)'}
              percent={(section + 1) * 100 / sections.length}

              showInfo={false} style={{ width: "100%" }} />
            <Space style={{ justifyContent: "space-between", width: "100%" }}>
              <Text strong className={getThemeColorOpac()}>Contacto</Text>
              <Space size={14}>
                <FacebookFilled className="red contact-section-icon" />
                <InstagramFilled className="red contact-section-icon" />
                <WhatsAppOutlined className="red contact-section-icon" />
              </Space>
            </Space>
          </div>
        </div>
        {/* Menu de Landing */}
        <div className="landing-menu">
          <div className="landing-menu-icon">
            <MenuOutlined onClick={() => this.setState({ visibleDrawer: !this.state.visibleDrawer })} className={"landing-menu-icon-" + theme} style={{ fontSize: 30, }} />

            <Drawer open={this.state.visibleDrawer} onClose={() => this.setState({ visibleDrawer: false })} title={null}>
              <Menu

                style={{ width: '100%' }}
                mode="inline"
                items={
                  sections.map(({ name, key }) => ({ label: name, key, onClick: e => this.fullPageRef.fullpageApi.moveTo(key) }))
                }
              />
            </Drawer>
          </div>
          <div className="landing-menu-items">
            <div className="landing-menu-items-container">
              {sections.map(({ key, name }, index) => <a
                onClick={e => this.fullPageRef.fullpageApi.moveTo(key)}
                key={key} className={"landing-menu-item landing-menu-item-" + theme + ((this.state.section == index) ? ' active' : '')}
                dataAnchor={key} ><span>{name}</span></a>)}
            </div>
          </div>
        </div>
        <ReactFullpage
          ref={ref => this.fullPageRef = ref}
          anchors={sections.map(({ key }) => key)}
          sectionSelector={SECTION_SEL}
          onLeave={this.onLeave}
          sectionsColor={sections.map(e => (e.theme == "dark") ? 'black' : 'white')}
          controlArrows={false}
          menu={false}
          resetSliders={true}
          render={(comp) => (
            <ReactFullpage.Wrapper >
              {sections.map(({ content, key }, index) => (
                <div key={key} className={SEL}>
                  {content({ index, current: this.state.section, ...comp })}
                </div>
              ))}
            </ReactFullpage.Wrapper>
          )}
        />
      </Content>
    );
  }
}
