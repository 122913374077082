/* eslint-disable import/no-extraneous-dependencies */
import React from "react";

import { Layout, Typography, Progress, Space, } from "antd"

import LandingPage from "./Components/Public/index"


import "../src/Styles/Theme/antd-home.css"


export default class App extends React.Component {

  render() {
    return <LandingPage />
  }
}
