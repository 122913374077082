import React, { useEffect, useState } from "react"
import { Layout, Space, Menu, ConfigProvider, Button, Typography, Progress, Carousel, Card, Statistic, Row, Col, Form, Input } from 'antd'
import { DownOutlined, FacebookFilled, InstagramFilled, MenuOutlined, UpOutlined, WechatFilled, WhatsAppOutlined } from '@ant-design/icons'
import { useWheel } from '@use-gesture/react'

const { Header, Footer, Sider, Content, } = Layout
const { Text, Title, Paragraph } = Typography

class AsNavFor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        })
    }

    sections = [
        {
            img: <img className="image-main" src='/img/logos-tech/wordpress.svg' style={{ position: "relative", top: 12 }} />,
            description: <Text className="description">
                <img className="image-content" src='/img/logos-tech/wordpress.svg' />
                Para la realización de sitios especificos de indole general, utilizamos la tecnologia de Wordpress para la creación y desarollo de sitios web, publicaciones y contenido en general.
                <br />
            </Text>
        },
        {
            img: <img src='/img/logos-tech/react.svg' className="image-main" style={{ position: "relative", top: 8 }} />,
            description: <Text className="description">
                <img className="image-content" src='/img/logos-tech/react.svg' />
                En el desarollo de software especializado, utilizamos la librería de React.js para la creación de Interfaces siempre las tecnicas UI/UX (User Interface / User Experience) para el diseño y desarollo de interfaces.
                <br />
            </Text>
        },
        {
            img: <img src='/img/logos-tech/express.svg' className="image-main" style={{ position: "relative", top: 8 }} />,
            description: <Text className="description">
                <img className="image-content" src='/img/logos-tech/express.svg' />
                Para la lógica del servidor, guardado de la información y backend en general, utilizamos Node.js junto con el framework de express. La versatibilidad y escalabilidad de estas tecnologías las hacen optimas para el desarollo de software web.
                <br />
            </Text>
        },
        {
            img: <img src='/img/logos-tech/javaspring.svg' className="image-main" style={{ position: "relative", top: 8 }} />,
            description: <Text className="description">
                <img className="image-content" src='/img/logos-tech/javaspring.svg' />
                ¿Tienes un despacho contable? ¿La seguridad es un aspecto fundamental en tu empresa? Considerando estos aspectos, utilizamos SPRING para la realización de software financiero, bancario y fiscal. Spring ofrece las herramientas necesarias para proteger tus datos y tu información.
                <br />
            </Text>
        },
        {
            img: <img src='/img/logos-tech/mysql.svg' className="image-main" style={{ position: "relative", top: 8 }} />,
            description: <Text className="description">
                <img className="image-content" src='/img/logos-tech/mysql.svg' />
                Utilizamos MySQL para nuestras implementaciones de bases de datos. MySQL es un sistema de gestión de bases de datos relacional ampliamente reconocido, conocido por su confiabilidad y eficiencia. Te proporciona las herramientas esenciales para garantizar transacciones seguras y operaciones rápidas, manteniendo la coherencia y la seguridad de tu información a lo largo del tiempo.
                <br />
            </Text>
        },
        {
            img: <img src='/img/logos-tech/mongodb.svg' className="image-main" style={{ position: "relative", top: 8 }} />,
            description: <Text className="description">
                <img className="image-content" src='/img/logos-tech/mongodb.svg' />
                Usamos MongoDB para nuestras soluciones en manejo de bases de datos. MongoDB es una base de datos NoSQL líder en su categoría, diseñada para proporcionar alta disponibilidad, escalabilidad y flexibilidad. 
                <br />
            </Text>
        },

    ]

    render() {
        return (
            <div style={{ width: "100%" }}>
                <Carousel
                    asNavFor={this.state.nav2}
                    ref={slider => (this.slider1 = slider)}
                    arrows={false}
                    adaptiveHeight={true}
                    slidesToShow={1}
                    swipeToSlide={true}
                    dots={false}
                >
                    {this.sections.map(({ img, description }) => {

                        return <Card size="small" className="card-tech">
                            {description}
                        </Card>
                    })}
                </Carousel>
                <div style={{ marginTop: '1em' }} />
                <Carousel
                    asNavFor={this.state.nav1}
                    ref={slider => (this.slider2 = slider)}
                    slidesToShow={5}
                    swipeToSlide={true}
                    focusOnSelect={true}
                    arrows={false}
                    dots={false}
                    responsive={[
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                            }
                        },
                        {
                            breakpoint: 576,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                            }
                        }
                    ]}
                // responsive={{}}
                >
                    {this.sections.map(({ img, description }) => {
                        return <div className="tech-content">
                            <Card className="card-tech" size="small">{img}</Card>
                        </div>
                    })}
                </Carousel>
            </div>
        );
    }
}


const Section = (props) => <section {...props}>{props.children}</section>


export default function App({ index, current }) {


    return (
        <Section className="servicios">
            <img src="/img/hexagon_tiny.svg" style={{ position: "absolute", right: 0, top: 0, transform: 'translateX(40%) translateY(-30%)' }} />
            <img src="/img/hexagon_middle.svg" style={{ position: "absolute", left: 0, bottom: 0, transform: 'translateX(-40%) translateY(40%)' }} />
            <Section className="section landing-section">
                <Content className="section-content" >
                    <Row gutter={[44, 0]}>
                        <Col xs={0} sm={0} md={0} lg={8}>
                            <img className="img-content" src="/img/presentation.png" />
                        </Col>
                        <Col md={24} lg={16}>
                            <Title level={3} className="servicios-title white">Utilizamos las tecnologías top en innovación y seguridad</Title>
                            <Paragraph className="servicios-subtitle description white">En nuestros desarrollos y proyectos buscamos siempre la mejor calidad posible utilizando las mejores tecnologías que ofrece el mercado siempre en pro de la innovación, seguridad y facilidad de uso.  </Paragraph>
                            <AsNavFor />
                        </Col>
                    </Row>
                </Content>
            </Section>
        </Section>
    )
}