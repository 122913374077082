import React, { useEffect, useState, useRef } from "react"
import { Layout, Space, Menu, ConfigProvider, Button, Typography, Progress, Carousel, Card, Statistic, Row, Col, Form, Input } from 'antd'
import { DownOutlined, FacebookFilled, InstagramFilled, MenuOutlined, UpOutlined, WechatFilled, WhatsAppOutlined } from '@ant-design/icons'
import { useWheel } from '@use-gesture/react'

const { Header, Footer, Sider, Content, } = Layout
const { Text, Title, Paragraph } = Typography

class AsNavFor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        })
    }

    sections = [
        {
            img: <img src='/img/logos-tech/mongodb.svg' style={{ width: '100%', maxHeight: 40, display: 'block' }} />,
            description: <Text>En nuestros desarollomos buscamos siempre la mejor calidad posible utilizando las mejores tecnologías que ofrece el mercado siempre en pro de la innovación, seguridad y facilidad de uso.</Text>
        },
        {
            img: <img src='/img/logos-tech/react.svg' style={{ width: '100%', maxHeight: 40, display: 'block' }} />,
            description: <Text>En nuestros desarollomos buscamos siempre la mejor calidad posible utilizando las mejores tecnologías que ofrece el mercado siempre en pro de la innovación, seguridad y facilidad de uso.</Text>
        },
        {
            img: <img src='/img/logos-tech/express.svg' style={{ width: '100%', maxHeight: 40, display: 'block' }} />,
            description: <Text>En nuestros desarollomos buscamos siempre la mejor calidad posible utilizando las mejores tecnologías que ofrece el mercado siempre en pro de la innovación, seguridad y facilidad de uso.</Text>
        },
        {
            img: <img src='/img/logos-tech/nodejs.svg' style={{ width: '100%', maxHeight: 40, display: 'block' }} />,
            description: <Text>En nuestros desarollomos buscamos siempre la mejor calidad posible utilizando las mejores tecnologías que ofrece el mercado siempre en pro de la innovación, seguridad y facilidad de uso.</Text>
        },
        {
            img: <img src='/img/logos-tech/scala.svg' style={{ width: '100%', maxHeight: 40, display: 'block' }} />,
            description: <Text>En nuestros desarollomos buscamos siempre la mejor calidad posible utilizando las mejores tecnologías que ofrece el mercado siempre en pro de la innovación, seguridad y facilidad de uso.</Text>
        }
    ]

    render() {
        return (
            <div style={{ width: "100%" }}>
                <Carousel
                    asNavFor={this.state.nav2}
                    ref={slider => (this.slider1 = slider)}
                    arrows={false}
                    adaptiveHeight={true}
                    slidesToShow={1}
                    swipeToSlide={true}
                    dots={false}
                >
                    {this.sections.map(({ img, description }) => {

                        return <Card size="small">
                            {img}
                            <br />
                            {description}
                        </Card>
                    })}
                </Carousel>
                <div style={{ marginTop: '1em' }} />
                <Carousel
                    asNavFor={this.state.nav1}
                    ref={slider => (this.slider2 = slider)}
                    slidesToShow={5}
                    swipeToSlide={true}
                    focusOnSelect={true}
                    arrows={false}
                    dots={false}
                >
                    {this.sections.map(({ img, description }) => {
                        return <div className="tech-content">
                            <Card className="card-tech" size="small">{img}</Card>

                        </div>
                    })}
                </Carousel>
            </div>
        );
    }
}


const TextoAnimado = ({
    palabras = [],
    tiempoEscritura = 100,
    tiempoEliminacion = 50,
    pausaEntrePalabras = 2000,
    cursorBlinkTime = 500,
    onFinalPalabra = () => { }
}) => {
    const [index, setIndex] = useState(0);
    const [subIndex, setSubIndex] = useState(0);
    const [reverse, setReverse] = useState(false);
    const [cursorVisible, setCursorVisible] = useState(true);

    const [nav1, setNav1] = useState(true);
    const [nav2, setNav2] = useState(true);

    useEffect(() => {
        const cursorTimeout = setInterval(() => { setCursorVisible((prevState) => !prevState); }, cursorBlinkTime);
        return () => clearInterval(cursorTimeout);
    }, []);

    useEffect(() => {
        if (!reverse && subIndex === palabras[index].length) {
            // console.log(`Se terminó de escribir la palabra: ${palabras[index]}`);
            setTimeout(() => {
                setReverse(true)
                // (index)
            }, pausaEntrePalabras);
            return;
        }

        if (reverse && subIndex === 0) {
            setReverse(false);
            setIndex((prevIndex) => prevIndex === palabras.length - 1 ? 0 : prevIndex + 1);
            onFinalPalabra(index)
            return;
        }

        const timeout = setTimeout(() => {
            setSubIndex((prevSubIndex) => prevSubIndex + (reverse ? -1 : 1));
        }, reverse ? tiempoEliminacion : tiempoEscritura);

        return () => clearTimeout(timeout);
    }, [subIndex, index, reverse]);


    return `${palabras[index].substring(0, subIndex)}${cursorVisible ? '|' : ' '}`
};


const Section = (props) => <section {...props}>{props.children}</section>


export default function App({ index, current, fullpageApi }) {

    // console.log('fullpageApi', fullpageApi);
    const [loading, setLoading] = useState(false)

    const carouselRef = useRef()
    const videoRef = useRef()

    if (!Array.isArray(window.videosRef))
        window.videosRef = []

    window.videosRef.push(videoRef)

    useEffect(() => {
        if (videoRef?.current?.paused && !loading && index == current)
            videoRef.current.play()
    })

    // id="home" name="home"
    return (
        <Section >
            <Section className="landing-section mainhead" >
                <video ref={videoRef} loop={true} muted={true} autoPlay={true} disableRemotePlayback={false} disablePictureInPicture={false} className="mainhead-video">
                    <source src="/videos/back.mp4" type="video/mp4" />
                </video>
                <div className="shadow" />
                <div className="container-mouse">
                    <span className="mouse-btn">
                        <span className="mouse-scroll"></span>
                    </span>
                </div>
                <Content className="mainhead-content" >
                    <Title className="mainhead-content-subtitle" level={4}>PROGRAMACIÓN Y DESAROLLO</Title>
                    <Title className="mainhead-content-title" level={1}><TextoAnimado
                        onFinalPalabra={() => carouselRef.current.next()}
                        pausaEntrePalabras={4000}
                        palabras={[
                            'MARKETING DIGITAL',
                            'DISEÑO DE SITIOS WEB',
                            'DESAROLLO DE SOFTWARE ESPECIALIZADO',
                            'DESAROLLO EN INTELIGENCIA ARTIFICIAL',
                            'ANALISIS DE DATOS'
                        ]}
                    /></Title>
                    <Carousel
                        accessibility
                        // className="carousel"
                        rootClassName="carousel"
                        fade
                        ref={carouselRef}
                        dots={false}
                        infinite>
                        <Paragraph className="mainhead-content-description">
                            Nos dedicamos a llevar tu marca al siguiente nivel a través de nuestras estrategias de marketing digital. Utilizamos una combinación de tácticas, como SEO, marketing en redes sociales y publicidad en línea para conectar tu marca con tu público objetivo de manera significativa.
                        </Paragraph>
                        <Paragraph className="mainhead-content-description">
                            Nuestros expertos en diseño web se centran en crear una experiencia de usuario excepcional, combinando estética con funcionalidad. No importa el tipo de sitio web que necesites, ya sea un blog, una tienda en línea o un sitio corporativo, podemos crear un diseño que se adapte a tus necesidades y atraiga a tu público objetivo.
                        </Paragraph>
                        <Paragraph className="mainhead-content-description">
                            Ofrecemos soluciones de software personalizadas que se ajustan a las necesidades específicas de tu empresa. Ya sea que necesites un sistema de gestión empresarial, una aplicación móvil o cualquier otro tipo de software, nuestro equipo de expertos puede proporcionarte una solución eficaz y de alta calidad.
                        </Paragraph>
                        <Paragraph className="mainhead-content-description">
                            Ya sea que necesites un chatbot para mejorar el servicio al cliente, un sistema de recomendación para aumentar las ventas o una solución de análisis predictivo para tomar decisiones más informadas, podemos ayudarte. Nuestro equipo de expertos en IA está preparado para brindarte soluciones de inteligencia artificial que marcarán la diferencia en tu negocio.
                        </Paragraph>
                        <Paragraph className="mainhead-content-description"> Nuestro equipo de analistas expertos puede ayudarte a transformar grandes volúmenes de datos en información valiosa. Con nuestras técnicas avanzadas de análisis de datos, puedes tomar decisiones basadas en datos, identificar nuevas oportunidades de crecimiento y optimizar el rendimiento de tu negocio.</Paragraph>
                    </Carousel>
                    <Row gutter={[16, 16]}>
                        <Col>
                            <Card size="small" className="info-card">
                                <Space size={10}>
                                    <Text className="info-card-number">12</Text>
                                    <Text className="info-card-title">PROYECTOS <br /> DESAROLLADOS</Text>
                                </Space>
                            </Card>
                        </Col>
                        <Col>
                            <Card size="small" className="info-card">
                                <Space size={10}>
                                    <Text className="info-card-number">10</Text>
                                    <Text className="info-card-title">CLIENTES <br /> SATISFECHOS</Text>
                                </Space>
                            </Card>
                        </Col>
                    </Row>
                    <br />
                    <Button danger type="primary" size="large" className="button-request" onClick={() => fullpageApi.moveTo("contacto")}>¡Quiero solicitar información!</Button>
                </Content>
                <div className='background-image-container'>
                    <img src='/img/logo.svg' className='background-image' />
                </div>
            </Section>
        </Section>
    )
}