import React, { useEffect, useState } from "react"
import { Layout, Space, Menu, ConfigProvider, Button, Typography, Progress, Carousel, Card, Statistic, Row, Col, Form, Input } from 'antd'
import { DownOutlined, FacebookFilled, InstagramFilled, MenuOutlined, UpOutlined, WechatFilled, WhatsAppOutlined } from '@ant-design/icons'
// import { useWheel } from '@use-gesture/react'

import Formulario from './Formulario'
const { Header, Footer, Sider, Content, } = Layout
const { Text, Title, Paragraph } = Typography


const Section = (props) => <section {...props}>{props.children}</section>


export default function App({ index, current }) {


    return (
        <Section className="contacto">
            <Section className="section landing-section">
                <Content className="section-content" >
                    <Row md={24} gutter={[24, 0]} style={{ width: "100%"}}>
                        <Col lg={8}>
                            {/* <img src="/img/presentation.png" style={{ maxHeight: 500, width: '100%' }} /> */}
                            <Title>Contacto</Title>
                            <Paragraph className="description">Llamanos para adquirir mas información</Paragraph>
                            <Row>
                                <Paragraph className="description">
                                    <a href="tel:+526631015160">
                                        +52 (663) 101 51 60
                                    </a>
                                </Paragraph>
                            </Row>
                            <Row>
                                <Paragraph className="description">
                                    <a href="mailto:admin@throwcode.com">
                                        contacto@throwcode.com
                                    </a>
                                </Paragraph>
                            </Row>
                            {/* <Row>
                                <Paragraph className="description">
                                    Lazaro Cardenas del Rio
                                    13018 Camino Verde
                                </Paragraph>
                            </Row> */}
                        </Col>
                        <Col sm={24} md={24} lg={16}>
                            <Formulario />
                        </Col>
                    </Row>
                </Content>
            </Section>
        </Section >
    )
}