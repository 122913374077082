import React, { useRef, useState } from 'react';
import { Form, Input, Button, Steps, Checkbox, InputNumber, Row, Col, message, Result } from 'antd';


import { parseNumber, formatNumber } from 'libphonenumber-js';

import axios from 'axios';

const { Step } = Steps;
const { TextArea } = Input;

const servicios = [
    {
        title: "SEO (Posicionamiento en Busquedas de Google)",
        value: "google_seo"
    },
    {
        title: "Marketing Digital con Meta (Facebook, Pixel)",
        value: "meta_marketing"
    },
    {
        title: "Ecommerce para la Venta de productos o servicios online",
        value: "ecommerce"
    },
    {
        title: "Módulo de Reserva de Citas",
        value: "citas"
    },
    {
        title: "Dominio .com, .org. mx, etc",
        value: "dominio"
    },
    {
        title: "Certificado de Seguridad",
        value: "certificado_seguridad"
    },
    {
        title: "Hospedaje Web (Hosting)",
        value: "hosting"
    },

]

const steps = [
    {
        title: 'Acerca de ti',
        content: (
            <Row gutter={[16, 12]}>
                <Col span={12}>
                    <Form.Item
                        label="Nombre"
                        name="nombre"
                        rules={[{ required: true, message: 'Por favor, ingrese su nombre!' }]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Apellidos"
                        name="apellidos"
                        rules={[{ required: true, message: 'Por favor, ingrese sus apellidos!' }]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Correo"
                        name="correo"
                        rules={[
                            { required: true, message: 'Por favor, ingrese su correo!' },
                            { type: 'email', message: 'Correo no es válido!' },
                        ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Teléfono"
                        name="telefono"
                        rules={[{ required: true, message: 'Por favor, ingrese su teléfono!' }]}
                    >
                        <InputNumber
                            size='large'
                            style={{ width: '100%' }}
                            formatter={value => {
                                if (!value) return "";
                                const parsed = parseNumber(value);
                                if (parsed.country) { // Si el número tiene un país asociado, es probablemente válido
                                    return formatNumber(parsed, 'International');
                                }
                                return value;  // Retorna el valor sin cambios si no es válido
                            }}
                            parser={value => value.replace(/\D/g, '')}  // Remueve cualquier carácter no numérico
                        />
                    </Form.Item>
                    {/* <Input size='large' /> */}
                </Col>
            </Row>
        ),
    },
    {
        title: 'Tus Intereses',
        content: (
            <Row gutter={[16, 12]}>
                <Col gutter={{
                    lg: [12, 12],
                    md: [12, 12],
                    sm: [6, 6]
                }}>
                    <Form.Item
                        label="Seleccione los servicios a adquirir"
                        name="servicios"
                    >
                        <Checkbox.Group>
                            <Row gutter={{
                                lg: [12, 12],
                                md: [12, 12],
                                sm: [6, 6]
                            }}>
                                {servicios.map(e => <Col sm={24} md={8}>
                                    <Checkbox value={e.value}>{e.title}</Checkbox>
                                </Col>)}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
                <Col sm={12} md={16}>
                    <Form.Item
                        name="dominio"
                        label="Dominio o Sitio Web"
                    // valuePropName="checked"
                    >
                        <Input size='large' placeholder='Ingresa el URL o el Dominio en caso de que dispongas de el' />
                    </Form.Item>
                </Col>
                <Col sm={12} md={8}>
                    <Form.Item
                        name="presupuesto"
                        label="Presupuesto"
                    >
                        <InputNumber
                            size='large'
                            min={0}
                            placeholder="$ 250,00"
                            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                            addonAfter="USD"
                        />
                    </Form.Item>
                </Col>
            </Row>
        ),
    },
    {
        title: 'Tu Proyecto',
        content: (
            <Form.Item
                style={{ marginBottom: '3em', width: '100%' }}
                label="Describe tu proyecto"
                name="descripcion_proyecto"
                rules={[{ required: true, message: 'Por favor, describe tu proyecto' }]}
            >
                <TextArea rows={4} />
            </Form.Item>
        ),
    },
]

const MultiStepForm = () => {

    const form = useRef()

    const [isFinal, setFinal] = useState(false)
    const [current, setCurrent] = useState(0)
    const [formData, setFormData] = useState({
        nombre: "",
        apellidos: "",
        correo: "",
        telefono: "",
        servicios: [],
        dominio: '',
        presupuesto: null,
        descripcion_proyecto: ""
    })

    const handleFormChange = (changedValues) => {
        setFormData(prevData => ({
            ...prevData,
            ...changedValues
        }));
    };

    const submitForm = () => {
        axios.post('https://throwcode.dev:4000/send', formData)
            .then(e => {
                message.success("Hemos enviado la solicitud")
                setFinal(!isFinal)
            })
            .catch(e => message.success("Error al enviar la solicitud"))
    }

    const next = () => {

        // console.log('THIS HITS');
        form.current.validateFields()
            .then(e => setCurrent(current + 1))
            .catch(e => message.error('Verifique que esté toda la información'))

    }

    const prev = () => {
        setCurrent(current - 1);
    };


    const renderButtons = () => {
        return <Row gutter={[12, 16]}>
            {current !== 0 && <Col>
                <Button size='large' onClick={prev}>
                    Anterior
                </Button>
            </Col>}
            <Col style={{ flex: 1 }}>
                {(current < steps.length - 1) ?
                    <Button size='large' block type="primary" onClick={next} htmlType='button'>
                        Siguiente
                    </Button>
                    :
                    <Button size='large' block type="primary" htmlType='submit'>
                        Finalizar
                    </Button>
                }
            </Col>
        </Row>
    }

    return !isFinal ? (
        <Form
            // name="multi_step_form"$
            className='form-contact'
            autoComplete="off"
            onFinish={submitForm}
            ref={form}
            initialValues={formData}
            onValuesChange={handleFormChange}
            layout='vertical'
        >
            <Steps current={current} size='small'>
                {steps.map(item => (
                    <Step key={item.title} title={item.title} />
                ))}
            </Steps>
            <div style={{ marginTop: '2em' }} className="steps-content">{steps[current].content}</div>
            <div className="steps-action" style={{ marginTop: '1em' }}>
                {renderButtons()}
            </div>
        </Form>
    ) : <Result
        status="success"
        title="¡Hemos recibido tu solicitud!"
        subTitle="Hemos recibido tu solicitud, nos pondremos en contacto contigo muy pronto."
        extra={[
            <Button type="primary" key="console" onClick={() => setFinal(!isFinal)}>Ir al Inicio</Button>,
        ]}
    />
};

export default MultiStepForm;
